<template>
    <div>
        <CRow class="mb-2">
            <CCol md="12">
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Administración Usuarios</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol md="4">
                                <multiselect class="m-0 d-inline-block" 
                                    v-model="tipoBDSelected"
                                    deselect-label="" 
                                    label="nombre" 
                                    placeholder="Tipo Base de Datos" 
                                    select-label=""
                                    selectedLabel=""
                                    :options="tipoBD" 
                                    :searchable="true"
                                    :loading="isLoadingTipoBD"
                                    @select="obtenerEmpresas"
                                    >
                                </multiselect>
                            </CCol>
                        </CRow>
                        
                        
                        <CRow>
                            <CCol md="12">
                                <a-table
                                    row-key="rutEmpresa"
                                    :columns="columnsEmpresas"
                                    :data-source="empresas"                                    
                                    @expand="expandirEmpresa"
                                    :scroll="{ y: 400 }"
                                    :loading="isLoadingEmpresas"
                                    class="components-table-demo-nested mt-3" 
                                    size="small" 
                                    style="overflow:auto;">

                                    <span slot="nombreEmpresaCustom">
                                        Nombre Empresa
                                    </span>
                                    
                                    <span slot="rutEmpresa" slot-scope="empresa">
                                        {{formatearRut(empresa.rutEmpresa)}}
                                    </span>
                                    
                                    <span slot="nombreEmpresa" slot-scope="empresa">
                                        ({{empresa.codEmpresa}}) {{empresa.nombreEmpresa}}
                                    </span>
                                    
                                    <span slot="acciones" slot-scope="empresa">
                                        <!--<CButton 
                                            class="btnPrincipalVapp"
                                            size="sm"
                                            v-c-tooltip.hover="{content:'Actualizar BD Empresa', placement:'left'}"
                                            :disabled="empresa.actualizandoEmpresa"
                                            @click="actualizarBDEmpresa(cliente, empresa)">
                                            <CIcon :content="$options.freeSet.cilLoopCircular" :class="empresa.actualizandoEmpresa?'imgr':''" />
                                        </CButton>
                                        
                                        <CButton v-show="empresa.mensajeActualizacion != null"
                                            class="ml-1 btnPrincipalVapp"
                                            size="sm"
                                            @click="mostrarMensajeActualizacion(empresa)">
                                            <CIcon :content="$options.freeSet.cilListRich" />
                                        </CButton>-->
                                    </span>
                                    <div slot="expandedRowRender" slot-scope="empresa" style="width:99%; margin: 0;">
                                        {{empresa}}
                                        <CTabs>
                                            <CTab active>
                                                <template slot="title" >
                                                    <CIcon name="cil-settings" />
                                                </template>

                                                <CRow style="padding: 10px;">
                                                    <CCol col="12">
                                                        configuración de documentos
                                                        tipo Documentos
                                                        documento 
                                                        subir documento
                                                        <!--<file-upload
                                                            class="btn btn-primary"
                                                            post-action="/upload/post"
                                                            extensions="gif,jpg,jpeg,png,webp"
                                                            accept="image/png,image/gif,image/jpeg,image/webp"
                                                            :multiple="true"
                                                            :size="1024 * 1024 * 10"
                                                            v-model="files"
                                                            @input-filter="inputFilter"
                                                            @input-file="inputFile"
                                                            ref="upload">
                                                            <i class="fa fa-plus"></i>
                                                            Agregar Archivos
                                                        </file-upload>-->

                                                    </CCol>
                                                </CRow>

                                            </CTab>
                                        
                                        </CTabs>
                                    </div>
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import Multiselect from 'vue-multiselect'
    import axios from "axios"
    import { freeSet } from '@coreui/icons'

    import { MultiListSelect } from 'vue-search-select'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    export default {
        name: 'AdministracionEmpresas',
        freeSet,
        components: {
            Multiselect,
            MultiListSelect,
            'Modal': VueModal
        },
        data () {
            return {
                urlBase: cons.port+"://" + cons.ipServer,

                tokenLogin: (this.$cookie.get('userLogginToken') != null)?JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario:'',

                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                
                tipoBDSelected: null,
                tipoBD: [],

                isLoadingTipoBD: false,
                isLoadingEmpresas: false,

                columnsEmpresas: [
                    { slots: { title: 'nombreEmpresaCustom' }, scopedSlots: { customRender: 'nombreEmpresa' }, ellipsis: true, align: 'left'},//, width: '30%'
                    { title: 'Rut Empresa', scopedSlots: { customRender: 'rutEmpresa'}, ellipsis: true, align: 'left'},//, width: '15%'
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, align: 'left'}//, width: '10%'
                ],
                empresas: [],

            }
        },
        created: function(){},
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            this.getTipoBds();
        },
        methods: {
            getTipoBds: function(){
                this.isLoadingTipoBD = true

                var url = this.urlBase + "/get_tiposbds/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.tipoBD = result.data;

                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingTipoBD = false
                });
            },
            obtenerEmpresas(tipoDb){
                this.tipoBDSelected = tipoDb;
                this.isLoadingEmpresas = true
                var url = this.urlBase + "/get_empresas_info/" + this.tipoBDSelected.valor;
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.empresas = result.data;
                    /*if(result.data.length <= 0){
                        this.textoSinEmpresas = "No hay empresas"
                    }*/
                    
                    this.isLoadingEmpresas = false
                }, error => {
                    
                    this.isLoadingEmpresas = false
                    let mensaje = `Problema al obtener empresas , ${error}`
                });
            },
            expandirEmpresa: function(expanded, empresa){
                if(expanded){
                    //if(!record.periodoCerrado)
                    //    this.actualizarDocumentosEmpresa(record)

                    
                }
            },
            formatearRut: function(rut){
                var rutaFormatear = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
                let len = rutaFormatear.length - 1;
                if (len > 3) {
                    let dv = rutaFormatear[len];
                    let beforeDv = rutaFormatear.substring(0, len).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    rutaFormatear = beforeDv + '-' + dv;
                }
                return rutaFormatear;
            },
            
            
        }
        
    }
</script>
<style scoped>

    .imgr{
        -webkit-animation: 3s rotate linear infinite;
        animation: 3s rotate  linear infinite;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    .tr.ant-table-expanded-row {
        background-color: #EBEDEF;
    }
</style>
